<template>
    <main class="m-curriculum">
        <img src="http://cssn.lexiangwx.com/png/R354726147348664320.png" alt="财商少年课程系列">
    </main>
</template>

<script>
    export default {
        name: "MCurriculum",
        metaInfo: {
            title: '财商少年 FQChildren',
            meta: [
                {
                    name: 'keyWords',
                    content: '四大课程体系,少儿财商启蒙,未来"CEO",家庭理财规划课堂,成人财商研修,财商学院,财商少年'
                },
                {
                    name: 'description',
                    content: '财商少年四大课程体系，为父母和孩子提供了丰富的财商教育成长课程。'
                }
            ]
        },
    }
</script>

<style lang="scss" scoped>
    .m-curriculum img{
        width: 100%;
    }
</style>
